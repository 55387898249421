
import { defineComponent, ref } from "vue";
import stubs from "@/init";
import { obtainRegisteredTaskDefinitions } from "./def";
import lodash from "lodash";

export default defineComponent({
  setup() {
    const taskDefs = obtainRegisteredTaskDefinitions(stubs.injector);
    const scenes = lodash.keys(taskDefs);
    const currentSceneType = ref('');
    return {
      scenes, taskDefs, currentSceneType
    }
  }
})
